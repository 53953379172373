import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background-color: #00bcd4;
  font-weight: bold; /* 폰트를 굵게 설정합니다. */
  color: white;

  nav {
    margin-left: 0;
  }
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  margin: 0;
  color: white;
  text-decoration: none;
`;

const Nav = styled.nav`
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start; /* 링크를 왼쪽으로 정렬합니다. */
  }

  li {
    margin-left: 1.5rem;
  }

  a {
    color: white;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 1.1rem; /* 폰트 크기를 조금 더 크게 설정합니다. */
    font-weight: bold; /* 폰트를 굵게 설정합니다. */

    &:hover {
      color: #1ed760;
    }
  }
`;


function Header() {
  return (
    <HeaderContainer>
      <Logo as={Link} to="/">시우라이팅</Logo>
      <Nav>
        <ul>
          <li>
            <Link to="/">홈</Link>
          </li>
          <li>
            <Link to="/music">음악</Link>
          </li>
        </ul>
      </Nav>
    </HeaderContainer>
  );
}

export default Header;