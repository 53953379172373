import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  color: #616161;
  font-size: 0.9rem;

  p {
    margin: 0.25rem 0;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <p>&copy; 2024 시우라이팅. All rights reserved.</p>
      <p>Contact Siwoo Kim : <a href="mailto:SiwooKimOfficial@gmail.com">SiwooKimOfficial@gmail.com</a></p>
    </FooterContainer>
  );
}

export default Footer;