import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 0.75rem 1.5rem; /* 패딩을 늘려서 버튼의 크기를 증가시킵니다. */
  font-size: 1.25rem; /* 글꼴 크기를 늘려서 텍스트가 더 크게 보이게 합니다. */
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f57c00;
  }
`;

function Button({ text, onClick }) {
  return <StyledButton onClick={onClick}>{text}</StyledButton>;
}

export default Button;
