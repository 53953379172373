// Home.js (가정)
import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 20rem);
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

function Home() {
  return (
    <HomeContainer>
      <Title>시우라이팅</Title>
      <Description>김시우의 음악취향을 강요해주는 사이트</Description>
      <Button text="노래 들어보기" onClick={() => (window.location.href = '/music?no=1')} />
    </HomeContainer>
  );
}

export default Home;