import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import MusicPlayer from '../components/MusicPlayer';
import Button from '../components/Button';

const MusicContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: calc(100vh - 6rem);
`;

const SongInfo = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
`;

const Music = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [musicNo, setMusicNo] = useState(1);
  const [musicData, setMusicData] = useState(null);
  const [totalSongs, setTotalSongs] = useState(0);

  // 음악 개수를 불러오는 useEffect
  useEffect(() => {
    const fetchMusicCount = async () => {
      try {
        const response = await axios.get('https://siwoo.xyz/api/music_count');
        setTotalSongs(response.data.count);
      } catch (error) {
        console.error('Failed to fetch music count:', error);
      }
    };
    fetchMusicCount();
  }, []);

  // URL 변경 시 노래 번호를 업데이트하는 useEffect
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const noParam = parseInt(searchParams.get('no'), 10);
    // totalSongs가 설정된 후에만 URL 파라미터 검증을 수행
    if (totalSongs > 0) {
      if (!isNaN(noParam) && noParam >= 1 && noParam <= totalSongs) {
        setMusicNo(noParam);
      } else {
        // noParam이 유효한 범위에 있지 않은 경우, 현재 musicNo가 이미 1이 아닐 때만 리다이렉션
        if (musicNo !== 1) {
          navigate('/music?no=1', { replace: true });
        }
      }
    }
  }, [location.search, totalSongs, navigate, musicNo]);

  // musicNo 상태 변경 시 노래 데이터를 불러오는 useEffect
  useEffect(() => {
    const fetchMusicData = async () => {
      try {
        const response = await axios.get(`https://siwoo.xyz/api/music?no=${musicNo}`);
        setMusicData(response.data);
      } catch (error) {
        console.error('Failed to fetch music data:', error);
      }
    };
    if (musicNo >= 1 && musicNo <= totalSongs) {
      fetchMusicData();
    }
  }, [musicNo, totalSongs]);

  // 이전 노래 버튼 클릭 핸들러
  const handlePrevClick = () => {
    const newMusicNo = musicNo > 1 ? musicNo - 1 : totalSongs;
    navigate(`/music?no=${newMusicNo}`);
  };

  // 다음 노래 버튼 클릭 핸들러
  const handleNextClick = () => {
    const newMusicNo = musicNo < totalSongs ? musicNo + 1 : 1;
    navigate(`/music?no=${newMusicNo}`);
  };

  // 음악이 끝났을 때 호출되는 핸들러
  const handleVideoEnd = () => {
    const newMusicNo = musicNo < totalSongs ? musicNo + 1 : 1;
    navigate(`/music?no=${newMusicNo}`);
  };

  if (!musicData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <MusicContainer>
        <SongInfo>
          {musicData.artist} - {musicData.title}
        </SongInfo>
        <MusicPlayer videoUrl={musicData.url} onVideoEnd={handleVideoEnd} />
        <ButtonContainer>
          <Button text="이전 노래" onClick={handlePrevClick} />
          <Button text="다음 노래" onClick={handleNextClick} />
        </ButtonContainer>
      </MusicContainer>
    </>
  );
};

export default Music;