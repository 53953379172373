import React, { useEffect, useRef } from 'react';

const MusicPlayer = ({ videoUrl, onVideoEnd }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    let player;

    const onPlayerReady = () => {
      player.loadVideoById(getVideoId(videoUrl));
    };

    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        onVideoEnd();
      }
    };

    const loadPlayer = () => {
      player = new window.YT.Player(playerRef.current, {
        height: '360',
        width: '640',
        videoId: getVideoId(videoUrl),
        playerVars: {
          autoplay: 1,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };

    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      window.onYouTubeIframeAPIReady = loadPlayer;
      document.body.appendChild(tag);
    } else {
      loadPlayer();
    }

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [videoUrl, onVideoEnd]);

  const getVideoId = (url) => {
    const videoIdRegex = /(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(videoIdRegex);
    return match && match[1].length === 11 ? match[1] : null;
  };

  return <div ref={playerRef} />;
};

export default MusicPlayer;